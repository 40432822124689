<template>
  <div id="group-management">
    <v-row no-gutters>
      <v-col cols="1" lg="0" md="0" sm="0" class="left-spacer">&nbsp;</v-col>
      <v-col cols="10" lg="12" xl="10" md="12" sm="12" class="main-content">
        <v-row class="page-title" no-gutters><span>Create User Groups</span> </v-row>
        <v-row class="action-form" no-gutters>
          <v-col cols="12">
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
              autocomplete="off"
              id="group-form"
            >
              <v-row no-gutters class="fill-height">
                <v-col cols="4" class="group-name">
                  <div class="border fill-height">
                    <v-row no-gutters>
                      <p class="label">User Group Name</p>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="groupName"
                          placeholder="New Group Name"
                          required
                          autocomplete="off"
                          :rules="groupNameRules"
                          clearable
                          class="input-border"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="4" class="menu">
                  <div class="border fill-height">
                    <v-row no-gutters>
                      <p class="label mb-0">Assessible Menus</p>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="6"
                        :key="index"
                        v-for="(value, name, index) in accessibleMenus"
                      >
                        <v-checkbox
                          v-model="selectedMenu"
                          :label="value"
                          :value="name"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="4" class="permission">
                  <div class="border fill-height">
                    <v-row no-gutters>
                      <p class="label mb-0">Allow Permissions To</p>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="4"
                        :key="index"
                        v-for="(value, name, index) in allowPermissions"
                      >
                        <v-checkbox
                          v-model="permissions"
                          :label="value"
                          :value="name"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <button class="submit-button">Add Group</button>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="table-view" no-gutters>
          <v-col cols="12">
            <v-row class="page-title" no-gutters>
              <span class="label">View User Groups</span>
            </v-row>
            <v-row no-gutters>
              <v-data-table
                :headers="headers"
                :items="groups"
                :items-per-page="pageSize"
                :search="search"
                @pagination="updatePagination"
                no-data-text="No Data"
                :footer-props="footer"
              >
                <template v-slot:top>
                  <v-row no-gutters>
                    <v-col cols="12" id="search-bar">
                      <v-text-field
                        v-model="search"
                        placeholder="Search User Groups"
                        class="search-input"
                        append-icon="mdi-magnify"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </template>
                <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 + (currentPage - 1) * pageSize }}
                </template>
                <template v-slot:[`item.accessible_menus`]="{ item }">
                  <v-row no-gutters>
                    <div class="menu-icon" v-if="item.accessible_menus.dashboard">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_home.svg"
                        transition="scale-transition"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.der">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_der.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.der"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.schedule">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_schedule.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.schedule"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.report">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_report.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.report"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.alarm">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_alarm.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.alarm"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.user">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_user.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.user"
                        width="34"
                        height="34"
                      />
                    </div>

                    <!-- <v-col cols="12" md="2" v-if="item.accessible_menus.dashboard">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_home.svg"
                        transition="scale-transition"
                        width="34"
                        height="34"
                      />
                    </v-col>
                    <v-col cols="12" md="2" v-if="item.accessible_menus.der">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_der.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.der"
                        width="34"
                        height="34"
                      />
                    </v-col>
                    <v-col cols="12" md="2" v-if="item.accessible_menus.schedule">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_schedule.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.schedule"
                        width="34"
                        height="34"
                      />
                    </v-col>
                    <v-col cols="12" md="2" v-if="item.accessible_menus.report">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_report.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.report"
                        width="34"
                        height="34"
                      />
                    </v-col>
                    <v-col cols="12" md="2" v-if="item.accessible_menus.alarm">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_alarm.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.alarm"
                        width="34"
                        height="34"
                      />
                    </v-col>
                    <v-col cols="12" md="2" v-if="item.accessible_menus.user">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_user.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.user"
                        width="34"
                        height="34"
                      />
                    </v-col> -->
                  </v-row>
                </template>
                <template v-slot:[`item.allowAdd`]="{ item }">
                  <v-icon :class="item.allowAdd ? `radio-on` : `radio-off`"
                    >mdi-circle-slice-8</v-icon
                  >
                </template>
                <template v-slot:[`item.allowEdit`]="{ item }">
                  <v-icon :class="item.allowEdit ? `radio-on` : `radio-off`"
                    >mdi-circle-slice-8</v-icon
                  >
                </template>
                <template v-slot:[`item.allowDelete`]="{ item }">
                  <v-icon :class="item.allowDelete ? `radio-on` : `radio-off`"
                    >mdi-circle-slice-8</v-icon
                  >
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-row no-gutters>
                    <v-col cols="12" md="4"
                      ><v-btn :ripple="false" icon>
                        <v-img
                          alt="Users"
                          class="shrink mr-2"
                          contain
                          src="../../assets/tables/Button_edit.svg"
                          transition="scale-transition"
                          @click="openDialog(item)"
                        /> </v-btn
                    ></v-col>
                    <v-col cols="12" md="4"
                      ><v-btn :ripple="false" icon>
                        <v-img
                          alt="Users"
                          class="shrink mr-2"
                          contain
                          src="../../assets/tables/Button_delete.svg"
                          transition="scale-transition"
                          @click="openConfirmDialog(item)"
                        /> </v-btn
                    ></v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" lg="0" md="0" sm="0" class="">&nbsp;</v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="1000" content-class="group-dialog">
      <v-card>
        <v-card-title>Edit User Group</v-card-title>
        <v-card-text>
          <v-form
            ref="formDialog"
            v-model="valid"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12" md="4" id="dialog-left-col">
                <v-card class="pa-2" outlined tile>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <p class="label">User Group Name</p>
                        <v-text-field
                          v-model="editGroupName"
                          placeholder="New User Group Name"
                          required
                          autocomplete="off"
                          :rules="groupNameRules"
                          clearable
                        >
                          ></v-text-field
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile id="dialog-mid-col">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <p class="label mb-0">Assessible Menus</p>
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            md="6"
                            :key="index"
                            v-for="(value, name, index) in accessibleMenus"
                          >
                            <v-checkbox
                              v-model="editSelectedMenu"
                              :label="value"
                              :value="name"
                              :ripple="false"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile id="dialog-right-col">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" md="12"
                        ><p class="label mb-0">Allow Permissions To</p></v-col
                      >
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="4"
                        :key="index"
                        v-for="(value, name, index) in allowPermissions"
                      >
                        <v-checkbox
                          v-model="editPermissions"
                          :label="value"
                          :value="name"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4" class="text-right mt-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4"
                    ><v-btn
                      :ripple="false"
                      @click="closeDialog"
                      class="cancel-button form-btn"
                      >Cancel</v-btn
                    ></v-col
                  >
                  <v-col cols="12" md="4"
                    ><v-btn :ripple="false" @click="revertEdit" class="form-btn"
                      >Revert</v-btn
                    ></v-col
                  >
                  <v-col cols="12" md="4"
                    ><v-btn
                      :ripple="false"
                      :disabled="!valid"
                      class="form-btn"
                      type="submit"
                      >Save
                    </v-btn></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-form></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="700px"
      content-class="group-dialog"
    >
      <v-card id="group-confirm-dialog">
        <v-card-title>Confirm</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="7" style="padding-top: 6px">
              <span
                >Are you sure you want to delete group
                {{ groupInfo ? groupInfo.name : "" }}?</span
              >
            </v-col>
            <v-col cols="12" md="5" class="text-right">
              <v-btn
                class="cancel-button form-btn"
                :ripple="false"
                @click="closeConfirmDialog"
                >CANCEL</v-btn
              >
              <v-btn class="form-btn" :ripple="false" @click="deleteGroup(groupInfo)"
                >YES</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" md="9" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn class="form-btn" :ripple="false" @click="closeMessage">OK</v-btn>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" src="../../assets/css/group-management.scss"></style>
<script>
import { accessibleMenus, allowPermissions, footerProps } from "../../contants";
import { groupService } from "../../services";

export default {
  data() {
    return {
      accessibleMenus,
      allowPermissions,
      groupName: "",
      selectedMenu: [],
      permissions: [],
      editGroupName: "",
      editSelectedMenu: [],
      editPermissions: [],
      editGroupId: null,
      groupInfo: null,
      search: "",
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "No", value: "no", sortable: false, filterable: false },
        {
          text: "User Group",
          align: "start",
          value: "name",
        },
        {
          text: "Menu Access",
          align: "start",
          value: "accessible_menus",
          sortable: false,
        },
        {
          text: "Allow Add",
          value: "allowAdd",
          sortable: false,
          filterable: false,
        },
        {
          text: "Allow Edit",
          value: "allowEdit",
          sortable: false,
          filterable: false,
        },
        {
          text: "Allow Delete",
          value: "allowDelete",
          sortable: false,
          filterable: false,
        },
        {
          text: "Date Created",
          align: "start",
          value: "created_at",
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      groups: [],
      currentPage: 1,
      pageSize: 6,
      groupNameRules: [
        (v) => !!v || "Input filled is required before you can create new user group",
      ],
      dialog: false,
      valid: false,
      footer: 0,
      total: 0,
      confirmDialog: false,
    };
  },
  created() {
    this.footer = Object.assign({}, this.footer, footerProps);
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      groupService
        .getGroupList()
        .then((res) => {
          if (res?.data?.data?.length) {
            const data = res.data.data;
            data.map((val) => {
              if (val.permissions) {
                val.allowAdd = !!val.permissions["users.create"];
                val.allowEdit = !!val.permissions["users.edit"];
                val.allowDelete = !!val.permissions["users.delete"];
              }
            });
            this.total = Math.ceil((res.data.data.length || 0) / this.pageSize);
            this.groups = [...data];
          }
        })
        .catch();
    },
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(this.footer, "pageText", `${this.currentPage} of ${this.total}`);
    },
    revert() {
      this.$refs.form.reset();
    },
    revertEdit() {
      this.setGroupInfo(this.groupInfo);
    },
    submit() {
      if (this.$refs.form.validate()) {
        const data = this.prepareData(
          this.groupName,
          this.permissions,
          this.selectedMenu
        );
        this.revert();
        groupService
          .storeUserGroup(data)
          .then(() => {
            this.getGroupList();
            this.apiMessage = "Group has been successfully created";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Group cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    submitEdit() {
      const data = this.prepareData(
        this.editGroupName,
        this.editPermissions,
        this.editSelectedMenu
      );
      const id = this.groupInfo.id;
      groupService
        .updateUserGroup(id, data)
        .then(() => {
          this.closeDialog();
          this.getGroupList();
          this.apiMessage = "Group's information has been successfully updated";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "Group's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    prepareData(groupName, permissions, selectedMenu) {
      const data = {
        name: groupName,
        accessible_menus: {
          dashboard: false,
          der: false,
          schedule: false,
          report: false,
          alarm: false,
          user: false,
        },
        permissions: {
          "users.create": 0,
          "users.edit": 0,
          "users.delete": 0,
          "userGroups.create": 0,
          "userGroups.edit": 0,
          "userGroups.delete": 0,
        },
      };
      permissions.map((value) => {
        data.permissions[`users.${value}`] = 1;
        data.permissions[`userGroups.${value}`] = 1;
      });
      selectedMenu.map((value) => (data.accessible_menus[value] = true));
      return data;
    },
    openConfirmDialog(item) {
      this.groupInfo = Object.assign({}, this.groupInfo, item);
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.groupInfo = null;
      this.confirmDialog = false;
    },
    deleteGroup(item) {
      groupService
        .deleteUserGroup(item.id)
        .then(() => {
          this.getGroupList();
          this.apiMessage = "Group has been deleted";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "Group cannot be deleted";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        })
        .finally(() => {
          this.closeConfirmDialog();
        });
    },
    getGroupInfo(id) {
      return groupService
        .getUserGroup(id)
        .then((res) => {
          if (res.data?.data) {
            const data = res.data.data;
            this.groupInfo = Object.assign({}, this.groupInfo, data);
            this.setGroupInfo(this.groupInfo);
          }
        })
        .catch(() => null);
    },
    setGroupInfo(data) {
      if (data) {
        this.editGroupName = data.name;
        if (data.permissions) {
          if (data.permissions["users.create"]) {
            this.editPermissions.push("create");
          }
          if (data.permissions["users.edit"]) {
            this.editPermissions.push("edit");
          }
          if (data.permissions["users.delete"]) {
            this.editPermissions.push("delete");
          }
        }
        if (data.accessible_menus) {
          const selected = Object.keys(data.accessible_menus).reduce((acc, cur) => {
            if (data.accessible_menus[cur]) {
              acc.push(cur);
            }
            return acc;
          }, []);
          this.editSelectedMenu = [...selected];
        }
      } else {
        this.editGroupName = "";
        this.editPermissions = [];
        this.editSelectedMenu = [];
        this.groupInfo = null;
      }
    },
    openDialog(item) {
      this.setGroupInfo(null);
      this.getGroupInfo(item.id).then(() => {
        this.dialog = true;
      });
    },
    closeDialog() {
      this.setGroupInfo(null);
      this.dialog = false;
    },
    closeMessage() {
      this.messageDialog = false;
    },
  },
};
</script>
